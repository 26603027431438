import React, { createContext, useContext, useState } from 'react';

import { User, LoginFormData } from './types';
import api from './api';
import { UserType } from './constants';
import useAuthStore from './zustand-store/use-auth-store';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext<{
  userLoading: boolean;
  user: User | null;
  login: (credentials: LoginFormData) => void;
  requestPasswordReset: (email: string) => void;
  passwordReset: (password: string, passwordResetToken: string) => void;
  logout: () => void;
  updateUserData: () => void;
  getUserData: () => Promise<void | null>;
  setUser: (data: any) => void;
  getAuthToken: () => string | null;
  isAffiliate: () => boolean;
}>({
  userLoading: false,
  user: null,
  login: async () => {},
  requestPasswordReset: async () => {},
  passwordReset: async () => {},
  logout: async () => {},
  updateUserData: async () => {},
  getUserData: async () => {},
  setUser: (data: any) => {},
  getAuthToken: () => null,
  isAffiliate: () => false,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const { setIsTermAgree, setLogindata } = useAuthStore();
  const navigate = useNavigate();

  const login = async (credentials: LoginFormData) => {
    const { data } = await api.post('/auth/login', { ...credentials, rememberMe: false });
    if (data?.data?.userRoles?.some((role: string) => role === UserType.ClientUser) && !data?.data?.isTermAccepted) {
      setIsTermAgree(true);
      setLogindata(data);
    localStorage.setItem('accessToken', data?.data?.accessToken);

      return;
    }
    navigate('/', { replace: true });

    localStorage.setItem('userId', data?.data?.userId);
    localStorage.setItem('accessToken', data?.data?.accessToken);
    setLogindata(data);


    await getUserData();
  };

  const requestPasswordReset = async (email: string) => {
    await api.post('/users/password-reset-request', { email });
  };

  const passwordReset = async (password: string, passwordResetToken: string) => {
    await api.patch('/users/password-reset', { password, passwordResetToken });
  };

  const logout = async () => {
    try {
      setUser(null);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userId');
      localStorage.clear()
      await api.post('/auth/logout');
    } catch (error) {
      console.error(error);
    }
  };

  const getAuthToken = () => {
    return localStorage.getItem('accessToken');
  };

  const getUserData = async () => {
    let retVal = null;

    setUserLoading(true);
    const userId = localStorage.getItem('userId');

    if (userId) {
      try {
        const {
          data: { data },
        } = await api.get(`/users/${userId}`);

        retVal = data;
        setUser({
          ...data,
        });
      } catch (error) {
        console.error(error);
        logout();
      }
    }

    setUserLoading(false);

    return retVal;
  };

  const updateUserData = async () => {
    const userId = localStorage.getItem('userId');

    if (userId) {
      try {
        const {
          data: { data },
        } = await api.get(`/users/${userId}`);
        setUser({
          ...data,
        });
      } catch (error) {
        console.error(error);
        logout();
      }
    }
  };

  const isAffiliate = () => {
    return user?.userType === 'affiliate partner user' || user?.userType === 'affiliate partner admin';
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        getUserData,
        userLoading,
        setUser,
        updateUserData,
        getAuthToken,
        isAffiliate,
        requestPasswordReset,
        passwordReset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
