import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Button, Input, Text, SimpleGrid, GridItem, Flex, Spacer } from '@chakra-ui/react';

import { useToastContext } from '../ToastContext';
import { Utils } from '../services';

import { FormField } from './FormField';
import { FieldValidationMessage } from './FieldValidationMessage';

interface Props {
  onSubmit: (data: any) => any;
  department?: any;
  saving: boolean;
  onDone: () => void;
}

const schema = yup.object().shape({
  name: yup.string().required(),
});

export const DepartmentForm: React.FC<Props> = ({ onSubmit, department, saving, onDone }) => {
  const toast = useToastContext();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleFormSubmit = async (formData: any) => {
    try {
      const resp = await onSubmit(formData);

      if (resp.data.errors) {
        let toastMessage = `An error occurred: \n`;

        resp.data.errors.forEach(({ message }: any) => (toastMessage += '* ' + message + '\n'));

        setErrorMessage(toastMessage);
      } else {
        onDone();
      }
    } catch (error: any) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Text color="brand.900" fontSize={'md'} mb={6}>
        {department ? `Here you can easily edit a department.` : `Here you can easily add a new department.`}
      </Text>

      <SimpleGrid columns={4} gap={4} rowGap={1} mt={1}>
        <GridItem colSpan={2}>
          <FormField
            fieldName={'name'}
            errors={errors}
            control={control}
            render={({ field }: any) => <Input {...field} autoFocus placeholder={'Department Name*'} />}
          />
        </GridItem>
        <GridItem colSpan={2}>
          {' '}
          <FormField
            fieldName={'contactPhone'}
            errors={errors}
            control={control}
            render={({ field }: any) => <Input {...field} placeholder={'Contact Phone'} />}
          />
        </GridItem>
      </SimpleGrid>

      {errorMessage && (
        <FieldValidationMessage mt={-2} pb={6}>
          {errorMessage}
        </FieldValidationMessage>
      )}

      <Flex direction="row" pt={6} justifyContent={'space-between'}>
        <Spacer></Spacer>
        <Button type="submit" isLoading={saving} mr={2}>
          Save
        </Button>
      </Flex>
    </Box>
  );
};

export default DepartmentForm;
