import { Box, Card, CardHeader, CardBody, FormControl, FormLabel, Switch, Button } from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect } from 'react';
import api from '../api';
import { useToastContext } from '../ToastContext';
import { quillModules } from '../utils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { htmlToText } from 'html-to-text';
import { Utils } from '../services';
interface IFormInputs {
  termsAccepted: boolean;
  termsText?: string;
}

const validationSchema = Yup.object().shape({
  termsAccepted: Yup.boolean().default(false),
  termsText: Yup.string().when('termsAccepted', {
    is: (val: boolean) => val,
    then: () => Yup.string().test('is-valid-text', 'This field is required', (value) => !!htmlToText(value || '').trim()),
    otherwise: () => Yup.string(),
  }),
});

const Terms = () => {
  const {
    control,
    handleSubmit,
    setValue,

    formState: { errors, isSubmitting },
  } = useForm<IFormInputs>({
    defaultValues: {
      termsAccepted: false,
      termsText: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const toast = useToastContext();

  const getTerms = async () => {
    try {
      const { data } = await api.get(`/terms`);
      if (data?.code === 200) {
        setValue('termsAccepted', data?.data?.is_active);
        setValue('termsText', data?.data?.description);
      }
    } catch (error: any) {
      return error.response;
    }
  };

  useEffect(() => {
    getTerms();
  }, []);

  // useEffect(() => {
  //   if (termsAccepted) return;

  //   setValue('termsText', '');
  // }, [termsAccepted]);

  const onSubmit = async (data: IFormInputs) => {
    const payload = {
      is_active: data?.termsAccepted,
      description: data?.termsText,
    };
    try {
      const { data } = await api.post('/terms', {
        ...Utils.cleanPayload(payload),
      });
      toast('Successfully created', 'Terms Saved Succesfully', 'success');
      await getTerms();

      return data;
    } catch (error: any) {
      return error.response;
    }
  };

  return (
    <Box p={4}>
      <Card variant="outline">
        <CardHeader fontSize="lg" fontWeight="bold" mt="5">
          Terms & Conditions
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl display="flex" alignItems="center" mb={4} isInvalid={!!errors.termsAccepted}>
              <FormLabel htmlFor="terms-switch" mb="0">
                Active
              </FormLabel>
              <Controller
                name="termsAccepted"
                control={control}
                disabled={isSubmitting}
                render={({ field }) => <Switch id="terms-switch" isChecked={field.value} onChange={field.onChange} />}
              />
            </FormControl>
            <FormControl mb={4} isInvalid={!!errors.termsText}>
              <FormLabel htmlFor="terms-textarea">Terms & Conditions</FormLabel>
              <Controller
                name="termsText"
                control={control}
                disabled={isSubmitting}
                render={({ field }) => (
                  // <Editor
                  //   apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                  //   value={field.value}
                  //   onEditorChange={field.onChange}
                  //   init={{
                  //     placeholder: 'Terms & Conditions',
                  //     height: 450,
                  //     toolbar:TinyReactToolBar,
                  //     plugins: TinyReactPlugIns,

                  //   }}
                  // />
                  <ReactQuill
                    id="terms-textarea"
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Terms & Conditions"
                    theme="snow"
                    modules={quillModules}
                    className="custom-quill"
                  />
                )}
              />
              <Box color="red.500" mt={1}>
                {errors.termsText?.message}
              </Box>
            </FormControl>
            <Button type="submit" isLoading={isSubmitting} colorScheme="blue" disabled={isSubmitting}>
              Save
            </Button>
          </form>
        </CardBody>
      </Card>
    </Box>
  );
};

export default Terms;
