import React, { useState, useEffect } from 'react';
import {
  Box,
  Input,
  Button,
  Stack,
  useBreakpointValue,
  StackDirection,
  Card,
  CardHeader,
  CardBody,
  useDisclosure,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuth } from '../AuthContext';
import ModalDialog from '../components/XModal';
import TOS from '../components/TOS';
import Privacy from '../components/Privacy';

import { LoginFormData } from '../types';
import useAuthStore from '../zustand-store/use-auth-store';
import TermsConditions from '../components/Terms&Condition';
import { UserType } from '../constants';

const schema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required().min(8),
});

const LoginPage: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: yupResolver(schema),
  });

  const { login, user } = useAuth();
  const navigate = useNavigate();
  const stackDirection = useBreakpointValue({ base: 'column', md: 'column' }) as StackDirection | undefined;

  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const { user:ClientUser, setIsTermAgree ,isTermAgree} = useAuthStore();


  const onSubmit = async (credentials: LoginFormData) => {
    try {
      setSubmitting(true);
      await login(credentials);
      // showToast('Logged in successfully!');
    } catch (error: any) {
      if (error.response?.data?.errors[0].code === 8000) {
        setErrorMessage('You have entered an invalid username or password. Try again, please.');
      } else {
        setErrorMessage('An error occurred. Please try again later.');
      }
    }

    setSubmitting(false);
  };

  useEffect(() => {
    if (user?.userType === UserType.ClientUser && !ClientUser?.data?.isTermAccepted) {
      return navigate('/login', { replace: true });
    }

    const userId = localStorage.getItem('userId');

    if (userId && user?.userType !== UserType.ClientUser) {
      return navigate('/', { replace: true });
    }
  }, []);

  const [isTermsContent, setIsTermsContent] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleTermsCLick = () => {
    setIsTermsContent(true);
    onOpen();
  };
  const handlePrivacyClick = () => {
    setIsTermsContent(false);
    onOpen();
  };

  const handleFormChange = () => {
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  return (
    <>
      <ModalDialog isOpen={isOpen} onClose={onClose} title={isTermsContent ? 'Terms of Service' : 'Privacy Policy'}>
        {isTermsContent ? <TOS /> : <Privacy />}
      </ModalDialog>
      <ModalDialog title='Terms & Conditions' isOpen={isTermAgree}  onClose={() => setIsTermAgree(false)}>
        <TermsConditions isdisplay={true} />
      </ModalDialog>
      <Card
        paddingY={9}
        paddingX={7}
        borderRadius={'20px'}
        width={'450px'}
        color="#878790"
        boxShadow={'0px 50px 50px 0px rgba(0, 0, 0, 0.25)'}
      >
        <CardHeader>
          <Heading size="xl" mb="3" color="#315D4F" fontWeight={'700'}>
            Login
          </Heading>
          <Text fontSize={'md'} lineHeight={'normal'} color="#878790">
            Type your username and password to continue.
          </Text>
        </CardHeader>
        <CardBody pt="10" pb="6">
          <form onSubmit={handleSubmit(onSubmit)} onChange={handleFormChange}>
            <Stack direction={stackDirection} spacing={2}>
              <Box>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <Input {...field} size="lg" placeholder={'Email'} />}
                />
                <Box minHeight={6} pt={1} fontSize={'sm'} color={'#F00'}>
                  {errors.email && 'A valid email is required'}
                </Box>
              </Box>
              <Box>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <Input {...field} type="password" size="lg" placeholder={'Password'} />}
                />

                <Box minHeight={6} pt={1} fontSize={'sm'} color={'#F00'}>
                  {errors.password && 'A valid password is required'}
                  <Box pt={3} pb={3} fontSize={'sm'} color={'#F00'}>
                    {!errors.password && errorMessage && <span>{errorMessage}</span>}
                  </Box>
                </Box>
              </Box>
              <Box>
                <Button size="lg" type="submit" mb="4" width={'100%'} isLoading={submitting}>
                  Login
                </Button>

                <Box pt={3} pb={5}>
                  <RouterLink to="/forgot-password">Forgot password?</RouterLink>
                </Box>
                <Text>
                  By clicking Login button, you agree to our <br />
                  <Button onClick={handleTermsCLick} variant={'link'}>
                    Terms of Service
                  </Button>{' '}
                  and{' '}
                  <Button variant={'link'} onClick={handlePrivacyClick}>
                    Privacy Policy
                  </Button>
                </Text>
              </Box>
            </Stack>
          </form>
        </CardBody>
      </Card>
    </>
  );
};

export default LoginPage;
