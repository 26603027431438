import React from 'react';
import cn from 'classnames';
import {
  Box,
  Stack,
  useBreakpointValue,
  StackDirection,
  Flex,
  Spacer,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as IcnMenu } from '../icons/icn-menu.svg';
import { ReactComponent as IcnLogout } from '../icons/icn-logout.svg';
import { ReactComponent as IcnUser } from '../icons/icn-user.svg';
import { ReactComponent as IcnTerms } from '../icons/icn-terms.svg';

import { useAuth } from '../AuthContext';
import { useUIContext } from '../UIContext';

import SideBarLink from './SideBarLink';

import './SideBar.scss';
import { UserType } from '../constants';
import useAuthStore from '../zustand-store/use-auth-store';
import ModalDialog from './XModal';
import TermsConditions from './Terms&Condition';

interface SideBarRoute {
  route: string;
  label: string;
  icon: JSX.Element;
}

interface Props {
  topRoutes: SideBarRoute[];
  bottomRoutes: SideBarRoute[];
  midlleRoutes?: SideBarRoute[];
}

const SideBar: React.FC<Props> = ({ topRoutes, bottomRoutes, midlleRoutes = [] }) => {
  const { logout, user } = useAuth();
  const { toggleSidebar, isSidebarOpen } = useUIContext();
  const { isTermAgree, setIsTermAgree } = useAuthStore();
  const navigate = useNavigate();
  const stackDirection = useBreakpointValue({ base: 'column' }) as StackDirection | undefined;

  const logoutUser = async () => {
    await logout();
    navigate('/login');
  };

  const handleToggleClick = () => {
    toggleSidebar();
  };

  return (
    <Box h="100%" className={cn('SideBar', !isSidebarOpen ? 'collapsed' : '')}>
      <Flex direction="column" h="100vh" px="0.75em">
        <Stack direction={stackDirection} gap="0">
          <Stack h="120px" direction="row" pt={'32px'} pl={!isSidebarOpen ? '.5em' : '.5em'}>
            <IconButton onClick={handleToggleClick} icon={<IcnMenu />} aria-label="Collapse" />
            {isSidebarOpen && (
              <Box pt={1}>
                <Link to={topRoutes[0].route} className="logo">
                  <img src="/xsilio_logo.svg" alt="Xsilio" />
                </Link>
              </Box>
            )}
          </Stack>
          {topRoutes.map((route) => (
            <SideBarLink to={route.route} key={route.label} label={route.label}>
              {route.icon}
            </SideBarLink>
          ))}
        </Stack>
        {midlleRoutes.length && (
          <Accordion bg="#63887B" borderRadius="30px" defaultIndex={[0]} allowToggle pt="2" mt="1" pb="0">
            <AccordionItem border={'none'} pb="0">
              <AccordionButton color="#fff">
                <Box ml="1">
                  <IcnUser stroke="#fff" />
                </Box>
                {isSidebarOpen && (
                  <Box ml="5">
                    Admin menu
                    <AccordionIcon color="#fff" ml="2" />
                  </Box>
                )}
              </AccordionButton>
              <AccordionPanel px="0" pb="0">
                {midlleRoutes.map((route) => (
                  <SideBarLink to={route.route} key={route.label} label={route.label}>
                    {route.icon}
                  </SideBarLink>
                ))}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        )}
        <Spacer />
        <Stack direction={stackDirection} pb="2.5em" gap="0">
          {user?.userType === UserType.ClientUser && (
            <SideBarLink onClick={() => setIsTermAgree(true)} label={'Terms & Conditions'}>
              <IcnTerms />
            </SideBarLink>
          )}

          {bottomRoutes.map((route) => (
            <SideBarLink to={route.route} key={route.label} label={route.label}>
              {route.icon}
            </SideBarLink>
          ))}
          <SideBarLink onClick={logoutUser} label={'Log Out'}>
            <IcnLogout />
          </SideBarLink>
        </Stack>
      </Flex>
      <ModalDialog title="Terms & Conditions" isOpen={isTermAgree} onClose={() => setIsTermAgree(false)}>
        <TermsConditions isdisplay={false} />
      </ModalDialog>
    </Box>
  );
};

export default SideBar;
