import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';

import TextHeading from './TextHeading';

interface Props {
  title?: string;
  children: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  isclosebtndisplay?: boolean;
}

export const ModalDialog: React.FC<Props> = ({ children, title, isOpen, onClose, size = 'xl',isclosebtndisplay=true }) => {
  return (
    <Modal size={size} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent color="#315D4F" paddingX={3} paddingY={5}>
         {isclosebtndisplay &&  <ModalCloseButton />}
        {title && (
          <ModalHeader>
            <TextHeading title={title} fontSize="21px" borderColor="#315D4F" paddingTop={2} paddingBottom={4} />
          </ModalHeader>
        )}

        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalDialog;
