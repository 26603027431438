import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type AuthState = {
  isTermAgree: boolean;
  setIsTermAgree: (payload: boolean) => void;
  user: any | null;
  setLogindata:(payload:any) => any
};

const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      isTermAgree: false,
      user: null,
      setIsTermAgree: (payload: boolean) =>
        set(() => ({
          isTermAgree: payload,
        })),
        setLogindata: (payload: Partial<any>) =>
          set((state) => ({
            user: {
              ...state.user,
              ...payload, 
            },
          })),
    }),

    {
      name: 'auth-storage',
      partialize: (state) => ({
        isTermAgree: state.isTermAgree,
        user:state.user
      }),
    }
  )
);

export default useAuthStore;
