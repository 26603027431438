import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import {BrowserRouter as Router} from 'react-router-dom'
import * as yup from 'yup';

import ErrorBoundary from './components/ErrorBoundary';

import AppRouter from './router/AppRouter';
import { AuthProvider } from './AuthContext';
// import { SocketProvider } from './SocketContext';
import { ToastProvider } from './ToastContext';
import { UIProvider } from './UIContext';

import theme from './theme';

yup.setLocale({
  mixed: {
    required: 'This field is required',
    notType: 'This field is required',
  },
  string: {
    uuid: 'This field is required',
  },
  number: {
    // typeError: 'This field must be numerical',
  },
});

function App() {
  return (
    <Router>
      <ChakraProvider theme={theme}>
      <AuthProvider>
        {/* <SocketProvider> */}
        <ErrorBoundary>
          <ToastProvider>
            <UIProvider>
              <AppRouter />
            </UIProvider>
          </ToastProvider>
        </ErrorBoundary>
        {/* </SocketProvider> */}
      </AuthProvider>
    </ChakraProvider>

    </Router>
    
  );
}

export default App;
