import { Box, Button, Checkbox, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import api from '../api';
import useAuthStore from '../zustand-store/use-auth-store';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import DOMPurify from 'dompurify';
import { Utils } from '../services';

interface Props {
  isdisplay: boolean;
}
const TermsConditions = ({ isdisplay = true }: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [terms, setTerms] = useState(null);
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const { getUserData } = useAuth();
  const { setIsTermAgree, setLogindata } = useAuthStore();

  const getTerms = async () => {
    try {
      const { data } = await api.get(`/terms`);
      if (data?.code === 200) {
        setTerms(data?.data?.description);
      }
    } catch (error: any) {
      return error.response;
    }
  };

  useEffect(() => {
    getTerms();
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleClick = async () => {
    try {
      const payload = {
        is_term_accepted: isChecked,
      };
      setSubmitting(true);

      const { data } = await api.post(`terms/terms-accepted`, {
        ...Utils.cleanPayload(payload)
      });
      if (data?.code === 200) {
        setLogindata({
          data: {
            isTermAccepted: true,
          },
        });

        localStorage.setItem('userId', user?.data?.userId);
        await getUserData();
        setIsTermAgree(false);
        setSubmitting(false);

        navigate('/', { replace: true });
      }
    } catch (error: any) {
      setSubmitting(false);

      return error.response;
    } finally {
      setSubmitting(false);
    }
  };

  const sanitizedTerms = terms
    ? DOMPurify.sanitize(terms, {
        ALLOWED_TAGS: [
          'table',
          'thead',
          'tbody',
          'tr',
          'td',
          'th',
          'colgroup',
          'col',
          'p',
          'div',
          'a',
          'strong',
          'em',
          'ul',
          'ol',
          'li',
          'span',
          'br',
          'h1',
          'h2',
          'h3',
          'h4',
          'h5',
          'h6',
          'blockquote',
          'section',
          'article',
          'u'
        ],

        ALLOWED_ATTR: ['style', 'border', 'cellpadding', 'cellspacing', 'width', 'height', 'href', 'target', 'rel', 'class', 'id'],
      })
    : null;

  return (
    <Box>
      <Box
        mb="4"
        maxH="450px"
        overflowY="auto"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
        padding="4"
        sx={{
          color: 'blackAlpha.900',
          '*': {
            color: 'inherit',
          },
          '[style*="text-align: left"]': { textAlign: 'left' },
          '[style*="text-align: center"]': { textAlign: 'center' },
          '[style*="text-align: right"]': { textAlign: 'right' },
          em: { fontStyle: 'italic' },
          strong: { fontWeight: 'bold' },
          'em strong, strong em': { fontWeight: 'bold', fontStyle: 'italic' },
          a: {
            color: '#1a73e8',
            textDecoration: 'underline',
            '&:hover': { textDecoration: 'none', color: '#0b57d0' },
          },
          u: {
            textDecoration: 'underline',
            textDecorationColor: '#1a73e8', 
            textDecorationThickness: '2px', 
            textUnderlineOffset: '2px', 
            '&:hover': {
              textDecorationColor: '#0b57d0', 
            },
          },
          
          h1: { fontSize: '2xl', fontWeight: 'bold', marginBottom: '0.5rem' },
          h2: { fontSize: 'xl', fontWeight: 'bold', marginBottom: '0.5rem' },
          h3: { fontSize: 'lg', fontWeight: 'bold', marginBottom: '0.5rem' },
          h4: { fontSize: 'md', fontWeight: 'bold', marginBottom: '0.5rem' },
          h5: { fontSize: 'sm', fontWeight: 'bold', marginBottom: '0.5rem' },
          h6: { fontSize: 'xs', fontWeight: 'bold', marginBottom: '0.5rem' },
          blockquote: {
            borderLeft: '4px solid #ccc',
            paddingLeft: '1rem',
            fontStyle: 'italic',
            color: 'gray.700',
            marginBottom: '1rem',
          },
          table: { width: '100%', borderCollapse: 'collapse' },
          'table, th, td': { border: '1px solid #ccc' },
          th: { backgroundColor: '#f5f5f5', textAlign: 'left', padding: '8px' },
          td: { padding: '8px' },
          ul: { paddingLeft: '1.5rem', marginBottom: '1rem' },
          ol: { paddingLeft: '1.5rem', marginBottom: '1rem' },
          li: { marginBottom: '0.5rem' },
          '&::-webkit-scrollbar': { width: '8px' },
          '&::-webkit-scrollbar-thumb': { background: '#315D4F', borderRadius: '4px' },
          '&::-webkit-scrollbar-thumb:hover': { background: '#23443A' },
          '&::-webkit-scrollbar-track': { background: '#E6EBE9' },
        }}
      >
        {sanitizedTerms ? (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizedTerms,
            }}
          />
        ) : (
          <Text mb="4">No Terms & Conditions Found</Text>
        )}
      </Box>

      {isdisplay && (
        <>
          <Checkbox size="lg" isChecked={isChecked} onChange={handleCheckboxChange} colorScheme="brand" mb="4">
            I accept the terms and conditions
          </Checkbox>

          <Flex justify="center">
            <Box mb="3">
              <Button isLoading={submitting} onClick={handleClick} size="lg" isDisabled={!isChecked}>
                Continue
              </Button>
            </Box>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default TermsConditions;
